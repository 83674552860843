import { useState, useEffect } from 'react';
import {
  Button,
  Avatar,
  Tooltip,
  MenuItem,
  Menu,
  Divider,
  ListItemIcon
} from '@mui/material';
import {
  Settings,
  Logout
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './accountButton.scss';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import DomainIcon from '@mui/icons-material/Domain';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import {useAuth0} from "@auth0/auth0-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function stringAvatar(name: string) {
  const split = name.split('');
  let avatar = '';
  if (split.length > 2) {
    avatar = name.split(' ')[0][0] + name.split(' ')[1][0];
  } else if (split.length > 1) {
    avatar = name.split(' ')[0][0];
  }

  return {
    sx: {
      bgcolor: '#183C59',
      mr: '8px',
      width: 36,
      height: 36
    },
    children: `${avatar}`,
  };
}

export function AccountButton() {
  const { t } = useTranslation();
  const {user, logout} = useAuth0();
  const [account, setAccount] = useState({
    name: '',
    firstname: '',
    environment: '',
    picture: ''
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const env = secureLocalStorage.getItem('environment') as any;
  //     setAccount(old => ({ ...old,
  //       name: user?.given_name + ' ' + user?.family_name,
  //       firstname: user?.given_name as string,
  //       environment: env?.name
  //       //picture: data.payload['picture']
  //     }));
  //   }
  //   setTimeout(() => { fetchData() }, 0);
  // }, [user]);

  return (
    <>
      <Tooltip title={t('account.title')}>
        <Button
          className='accountBtn'
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <div className='accountBtnContainer'>
              <Avatar src={user!.picture}
                      variant={"circular"} />
            {/*<div className='accountNames'>*/}
            {/*  {*/}
            {/*    account?.firstname ?*/}
            {/*      <>*/}
            {/*        <div className='accountName'>{account.firstname}</div>*/}
            {/*        <div className='accountEmail'>{user!.email}</div>*/}
            {/*      </>*/}
            {/*      :*/}
            {/*      <div className='accountName'>{user!.email}</div>*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          {t('account.myProfile')}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('account.settings')}
        </MenuItem>
        <Divider />
        {/*<MenuItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Settings fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  {t('account.settings')}*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={() => {*/}
        {/*  navigate('/environments/new');*/}
        {/*}}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <DomainAddIcon fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  {t('account.createCompany')}*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={() => {*/}
        {/*  navigate('/environments');*/}
        {/*}}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <DomainIcon fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  {t('account.switchCompany')}*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={() => {
          secureLocalStorage.clear();
          logout({
            clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
            logoutParams: {
              returnTo: `${process.env.REACT_APP_AUTH_LOGOUT_URL}`
            }
          });
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('account.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
