import { useState, MouseEventHandler, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
  SelectInstance
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';
import './property.scss';

export type MultiselectPropertyProps = {
  min?: number,
  max?: number,
  label: string,
  required?: boolean
  propertyName: string,
  state: any,
  description?: string,
  updateValue: (value: string | undefined) => any
};

export type SelectOption = {
  value: string,
  label: string,
};

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<string>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<SelectOption, true> & SortableContainerProps
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function MultiselectProperty(props: MultiselectPropertyProps) {
  // const [value, setValue] = useState<boolean | undefined>(Boolean(props.state.data.properties[props.propertyName]));
  const [selected, setSelected] = useState<readonly SelectOption[]>([]);
  const [editMode, setEditMode] = useState(false);
  const selectRef = useRef<SelectInstance | null>(null);
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  const onChange = (selectedOptions: OnChangeValue<SelectOption, true>) =>
    setSelected(selectedOptions);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

  return (
    <div className='property_container'>
      <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        {
          !editMode ?
            <Typography variant="body1" gutterBottom onClick={() => setEditMode(true)} component="span">
              {selected?.length > 0 ? selected.map(x => x.label).join(', ') : 'Not set'}
            </Typography>
          :
            <SortableSelect
              useDragHandle
              // react-sortable-hoc props:
              axis="xy"
              onSortEnd={onSortEnd}
              distance={4}
              // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              // react-select props:
              onBlur={() => { setEditMode(false)}}
              inputRef={input => input && input.focus()}
              isMulti
              options={options}
              value={selected}
              onChange={onChange}
              components={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                MultiValue: SortableMultiValue,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                MultiValueLabel: SortableMultiValueLabel,
              }}
              closeMenuOnSelect={false}
            />
        }
      </div>
    </div>
  )
}
