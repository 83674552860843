import {useNavigate, useParams} from 'react-router-dom';
import './propertyunits.scss';
import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import SaveIcon from "@mui/icons-material/Save";
import {PropertyUnit} from "../../api";

type PropertyUnitState = {
  isLoading: boolean,
  data: PropertyUnit,
  hasModification: boolean
}

export function PropertyUnitDetails() {
  const navigate = useNavigate();
  const { propertyUnitId } = useParams();
  const [state, setState] = useState<PropertyUnitState>({
    isLoading: false,
    data: new PropertyUnit(),
    hasModification: false
  });

  const fetchData = async () => {
    if (propertyUnitId !== undefined) {
      setState(old => ({...old, isLoading: true}));
      const organization = secureLocalStorage.getItem('organization') as any;
      httpClient
        .get<PropertyUnit>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertyunits/' + propertyUnitId)
        .then(response => {
          setState(old => ({...old, isLoading: false, data: response.data}));
        })
        .catch(() => {
          setState(old => ({...old, isLoading: false}));
        });
    }
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 0);
  }, []);

  const save = async () => {
    setState(old => ({...old, isLoading: true}));

    const organization = secureLocalStorage.getItem('organization') as any;

    if (propertyUnitId === undefined) {
      await httpClient
        .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertyunits', JSON.stringify(state.data))
        .then(async () => {
          navigate('/settings/properties/units');
        });
    } else {
      await httpClient
        .put('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertyunits/' + propertyUnitId, JSON.stringify(state.data))
        .then(async () => {
          navigate('/settings/properties/units');
        });
    }

    setState(old => ({...old, isLoading: false}));
  };

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          <a onClick={() => {
            navigate('/settings/properties/units');
          }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
          {propertyUnitId === undefined ? "New property unit" : "Edit property unit"}
        </div>
        <div className="actions">
          <Button startIcon={<SaveIcon/>} onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div className="content">
        <div className="formcontainer">
          <TextField
              required
              label={'Name'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{ shrink: true }}
              value={state.data?.name}
              onChange={(e) => {
                const newData = state.data;
                newData.name = e.target.value;
                setState(old => ({...old, data: newData}));
              }}
            />
            <TextField
              required
              label={'Unit'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{ shrink: true }} value={state.data?.unit}
              onChange={(e) => {
                const newData = state.data;
                newData.unit = e.target.value;
                setState(old => ({...old, data: newData}));
              }}
            />
            <TextField
              label={'Description'}
              variant="outlined"
              size="small"
              margin="dense"
              multiline
              rows={4}
              sx={{width: '400px'}}
              InputLabelProps={{ shrink: true }} value={state.data?.description}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.description = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}
            />
        </div>
      </div>
    </div>
  );
}
