import { Ribbon } from '../../components';
import './landing.scss';
import Typography from "@mui/material/Typography";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Landing() {

  return (
    <>
      <div className="maincentercontainer">
        <div className='maincontainer'>
          <img src='/logo.png' alt='logo' className='smalllogoimg' />
          <Typography variant="h4" gutterBottom color='#FD9367'>Development Environment</Typography>
          <Typography variant="h6" gutterBottom color='#183C59'>contact@jabs-solutions.com</Typography>
        </div>
      </div>
    </>
  );
}
