import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import './account.scss';
import httpClient from "../../api/AxiosInstance";
import {useAuth0} from "@auth0/auth0-react";
import {UserProfile} from "../../api";
import {TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";

export function RegisterAccount() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [showForm, setShowForm] = useState(false);
  const { register, handleSubmit, setValue } = useForm();

  const fetchData = async () => {

    await httpClient
      .get<UserProfile>("/api/v1/users/" + user!["https://jabs/app_metadata"]?.jabs_user_id)
      .then(async res => {

        secureLocalStorage.setItem('profile', res.data);

        if (!res.data.profileCompleted) {
          setValue('firstname', res.data.firstname);
          setValue('lastname', res.data.lastname);
          setValue('email', res.data.email);
          setValue('phone', res.data.phone);
          setShowForm(true);
          return;
        }

        navigate('/register/organization');
      });
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 100);
    return;
  }, []);

  const onSubmit2 = async (data) => {

    const formData = new FormData();
    formData.append("model", JSON.stringify(data));

    const profile = secureLocalStorage.getItem('profile') as UserProfile;

    await httpClient
      .put<UserProfile>('/api/v1/users/' + profile.id, formData)
      .then(async res => secureLocalStorage.setItem('profile', res.data))
      .then(() => navigate('/register/organization'));
  };

return (
    <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      {!showForm ?
        <div style={{fontSize:'1.1em', paddingTop:'12px', paddingBottom:'12px'}}>
          Loading your profile...
        </div>
        :
        <>
          <div style={{fontSize:'1.3em', paddingTop:'12px'}}>
            Welcome to the JABS community!
          </div>
          <div style={{fontSize:'1.1em', paddingTop:'12px', paddingBottom:'12px'}}>
            First, tell us more about you.
          </div>
          <form className="formcontainer" onSubmit={handleSubmit(onSubmit2)}>
            <TextField
              required
              label='Email Address'
              variant="outlined"
              size="small"
              margin="dense"
              disabled={true}
              {...register("email")}
              sx={{ width: '400px' }}
            />
            <TextField
              required
              label='First Name'
              variant="outlined"
              size="small"
              margin="dense"
              {...register("firstname")}
              sx={{ width: '400px' }}
            />
            <TextField
              required
              label='Last Name'
              variant="outlined"
              size="small"
              margin="dense"
              {...register("lastname")}
              sx={{ width: '400px' }}
            />
            <TextField
              label='Phone Number'
              variant="outlined"
              size="small"
              margin="dense"
              {...register("phone")}
              sx={{ width: '400px' }}
            />
            <LoadingButton type={"submit"} variant="contained" sx={{ mt: 2, mb: 1, width: '400px' }} >
              Save
            </LoadingButton>
          </form>
        </>
      }
    </div>
  );
}
