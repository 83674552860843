import { Box, Toolbar } from '@mui/material';
import {AccountButton, LeftMenu, PublicRibbon, Ribbon} from '../components';
import "./layout.scss"
import {Outlet} from "react-router-dom";

export const PageLayout = () => {

  return (
    <div className='app-frame '>
      <div className='content-frame'>
        <div className='ribbon'>
          <Toolbar>
            <Box sx={{flexGrow: 0}}>
              <div className="ribbontoplogocircle">
                <img className="ribbonmenuimage" src="/logo.png" alt={'logo'}/>
              </div>
            </Box>
            <Box sx={{flexGrow: 1}} style={{paddingLeft: '36px', display:'flex', gap:'12px'}} />
            <Box sx={{flexGrow: 0}}>
              <AccountButton/>
            </Box>
          </Toolbar>
        </div>
        <div className='app-content'>
          <Outlet/>
        </div>
      </div>
    </div>
  );
};
