import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";

export function Login() {
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const fetchData = async () => {

      const invitation = searchParams.get('invitation');
      const organization = searchParams.get('organization');

      if (invitation && organization) {
        await loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
            audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
            scope: "openid profile email",
            invitation: invitation,
            organization: organization,
          }
        });
      } else {
        navigate('');
      }
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 100);
    return;
  }, []);

  return (
    <div className="formcontainer">
      Redirecting to authentication provider
    </div>
  );
}
