import {Navigate, Outlet, useNavigate} from "react-router-dom";
import * as React from "react";
import secureLocalStorage from "react-secure-storage";
import {UserProfile} from "./datatype";
import httpClient from "./AxiosInstance";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";

export function ProfileGuard() {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [ element, setElement ] = useState<JSX.Element>(<></>);

  const validateProfile = async () => {
    const profile = secureLocalStorage.getItem('profile') as UserProfile;

    if (profile) {
      setElement(<Outlet />);
      return;
    }

    secureLocalStorage.removeItem('organization');

    await httpClient
      .get<UserProfile>("/api/v1/users/" + user!["https://jabs/app_metadata"]?.jabs_user_id)
      .then(async res => {
        secureLocalStorage.setItem('profile', res.data);
        if (!res.data.profileCompleted) {
          navigate('/register/account');
          return;
        }

        setElement(<Outlet />);
      });
  }

  useEffect(() => {
    setTimeout(async () => { await validateProfile() }, 100);
    return () => {};
  }, []);

  return element;
}
