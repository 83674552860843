import './imports.scss';
import {Button, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useForm} from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import {Import, JsonProblem} from "../../api";

export function ImportFile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit2 = async (data) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    //data = { ...data, title: data.file[0]?.name };
    formData.append("model", JSON.stringify(data));

    const organization = secureLocalStorage.getItem('organization') as any;

    await httpClient
      .post<Import>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/imports`, formData)
      .then(async res => {
        navigate(`/imports/${res.data.id}`);
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response) as JsonProblem;
        alert(errorMessage.type);
      });

    setIsLoading(false);
  };

  return (
    <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>

      <div style={{fontSize:'1.1em', paddingTop:'12px', paddingBottom:'12px'}}>
        Import a file
      </div>
      <form className="formcontainer" onSubmit={handleSubmit(onSubmit2)}>
        <TextField
          required
          label='Title'
          variant="outlined"
          size="small"
          margin="dense"
          {...register("title")}
          sx={{ width: '400px' }}
        />
          <Button
            variant="text"
            component="label">
            Upload file
            <input
              {...register("file")}
              type="file"
              hidden
            />
          </Button>
        <LoadingButton loading={isLoading} type={"submit"} variant="contained" sx={{ mt: 2, mb: 1, width: '400px' }} >
          Import
        </LoadingButton>
      </form>
    </div>
  );
}
