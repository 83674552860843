import './imports.scss';
import {useNavigate} from "react-router-dom";
import {TimeSpan} from "../../api";

export function ImportSucceeded({model}) {
  const navigate = useNavigate();

  return (
    <>
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            <a onClick={() => {
              navigate('/imports');
            }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
            Import completed!
          </div>
        </div>
        <div className="content">
          <div className="innerVerticalContent">
            <div>Total rows in file: {model.statistics.totalRows}</div>
            <div>Added rows: {model.statistics.addedProducts}</div>
            <div>Updated rows: {model.statistics.updatedProducts}</div>
            <div>File Loading Duration: {model.statistics.fileLoadingDuration}</div>
            <div>Validation Duration: {model.statistics.validationDuration}</div>
            <div>Import Duration: {model.statistics.importDuration}</div>
            <div>Merge Duration: {model.statistics.mergeDuration}</div>
            <div>Cleanup Duration: {model.statistics.cleanupDuration}</div>
          </div>
        </div>
      </div>
    </>
  );
}
