import axios, {
  AxiosError,
  AxiosResponse
} from 'axios';
import {useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const AxiosInstance = ({ children }: any) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const requestInterceptor = async (config: any) => {
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      else {
        config.headers['Content-Type'] = 'application/json';
      }

      const token = await getAccessTokenSilently();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }

      return config;
    };

    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

    const errInterceptor = async (error: AxiosError) => {
      const originalRequest = error.config!;
      //navigate('/login');
      return Promise.reject(error);
    };

    const req = httpClient.interceptors.request.use(
      requestInterceptor,
      (error) => {
        console.log('interceptor req error', error);
      }
    );

    const res = httpClient.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      httpClient.interceptors.request.eject(req);
      httpClient.interceptors.response.eject(res);
    };
  }, [getAccessTokenSilently, httpClient]);

  return children;
}

export default httpClient;
export { AxiosInstance };
