import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import './products.scss';
import httpClient from "../../api/AxiosInstance";
import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useForm} from "react-hook-form";

export function CreateProduct() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit2 = async (data) => {
    setIsLoading(true);

    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .post(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/products`, JSON.stringify(data))
      .then(async res => {
        navigate(`/products/${res.data.id}`);
      });

    setIsLoading(false);
  };
  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          <a onClick={() => {
            navigate('/products');
          }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
          Create new product
        </div>
      </div>
      <div className="content">
        <form className="formcontainer" onSubmit={handleSubmit(onSubmit2)}>
          <TextField
            required
            label={'SKU'}
            variant="outlined"
            size="small"
            margin="dense"
            {...register("productid")}
            sx={{width: '400px'}}
          />
          <TextField
            required
            label={'Label'}
            variant="outlined"
            size="small"
            margin="dense"
            {...register("label")}
            sx={{width: '400px'}}
          />
          <LoadingButton loading={isLoading} type={"submit"} variant="contained" sx={{mt: 2, mb: 1, width: '400px'}}>
            {'Create new product'}
          </LoadingButton>
        </form>
      </div>
    </div>
  );
}
