import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import Button from "@mui/material/Button";
import {forwardRef} from "react";
import {TransitionProps} from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function DeletePopup({isVisible, setIsVisible, title, message, action}) {

  const handleClose = () => {
    setIsVisible(false);
  };

  const executeDelete = () => {
    action();
    setIsVisible(false);
  };

  return (
    <>
      <Dialog
        open={isVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size={'small'} onClick={handleClose}>Cancel</Button>
          <Button size={'small'} variant={"contained"} color={"error"} onClick={executeDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
