import './addressEditor.scss';
import {CustomerAddress} from "../../api";
import {TextField} from "@mui/material";

export type AddressEditorProps = {
  title: string;
  address: CustomerAddress | undefined;
  onChange: (address: CustomerAddress) => any;
}

export function AddressEditor(props: AddressEditorProps) {

  return (
    <div className={'addressEditor'}>
      <fieldset className={'addressEditor-fieldset'}>
        <legend>{props.title}</legend>
        <TextField
          required
          label={'Street'}
          variant="outlined"
          size="small"
          margin="dense"
          sx={{width: '400px'}}
          InputLabelProps={{ shrink: true }}
          value={props.address?.street}
          onChange={(e) => {
            const newData = props.address ?? new CustomerAddress();
            newData.street = e.target.value;
            props.onChange(newData);
          }}
        />
        <TextField
          required
          label={'City'}
          variant="outlined"
          size="small"
          margin="dense"
          sx={{width: '400px'}}
          InputLabelProps={{ shrink: true }}
          value={props.address?.city}
          onChange={(e) => {
            const newData = props.address ?? new CustomerAddress();
            newData.city = e.target.value;
            props.onChange(newData);
          }}
        />
        <TextField
          required
          label={'Zip Code'}
          variant="outlined"
          size="small"
          margin="dense"
          sx={{width: '400px'}}
          InputLabelProps={{ shrink: true }}
          value={props.address?.zipCode}
          onChange={(e) => {
            const newData = props.address ?? new CustomerAddress();
            newData.zipCode = e.target.value;
            props.onChange(newData);
          }}
        />
        <TextField
          required
          label={'StateOrProvince'}
          variant="outlined"
          size="small"
          margin="dense"
          sx={{width: '400px'}}
          InputLabelProps={{ shrink: true }}
          value={props.address?.stateOrProvince}
          onChange={(e) => {
            const newData = props.address ?? new CustomerAddress();
            newData.stateOrProvince = e.target.value;
            props.onChange(newData);
          }}
        />
        <TextField
          required
          label={'Country'}
          variant="outlined"
          size="small"
          margin="dense"
          sx={{width: '400px'}}
          InputLabelProps={{ shrink: true }}
          value={props.address?.country}
          onChange={(e) => {
            const newData = props.address ?? new CustomerAddress();
            newData.country = e.target.value;
            props.onChange(newData);
          }}
        />
      </fieldset>
    </div>
  );
}
