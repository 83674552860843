import { DebugBanner } from './components';
import AppRoutes from './AppRoutes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Toaster } from 'react-hot-toast';
import {AxiosInstance} from "./api/AxiosInstance";
import {SharedStateProvider} from "./shared/Store";
import {Suspense} from "react";
import '../styles/global.scss';
import {Auth0ProviderWithRedirectCallback} from "./api/ProtectedRoute";
import {CssBaseline} from "@mui/material";

export default function App() {

  return (
    <Suspense fallback={<div>Loading... </div>}>
      <SharedStateProvider>
        <Auth0ProviderWithRedirectCallback
          useRefreshTokens={true}
          domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
          clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
          cacheLocation={'localstorage'}
          authorizationParams={{
            redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
            audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
            scope: "openid profile email",
            //organization: `${process.env.REACT_APP_AUTH_ORGANIZATION}`
          }}
        >
          <AxiosInstance>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <>
                <CssBaseline />
                <DebugBanner />
                <AppRoutes />
                <Toaster toastOptions={{
                  position: 'bottom-right',
                  style: {
                    border: '1px solid #012F44',
                    padding: '16px',
                    color: '#012F44',
                  },
                  success: {
                    iconTheme: {
                      primary: '#012F44',
                      secondary: '#FFFFFF',
                    }
                  },
                  error: {
                    iconTheme: {
                      primary: '#FF0000',
                      secondary: '#FFFFFF',
                    }
                  }
                }}/>
              </>
            </LocalizationProvider>
          </AxiosInstance>
        </Auth0ProviderWithRedirectCallback>
      </SharedStateProvider>
    </Suspense>
  );
}
