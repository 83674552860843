import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import './property.scss';

export type BooleanPropertyProps = {
    label: string,
    indeterminate: boolean,
    propertyName: string,
    state: any,
    description?: string,
    text?: string,
    updateValue: (value: string | undefined) => any
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function BooleanProperty(props: BooleanPropertyProps) {
  const [value, setValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (props.state?.data?.properties) {
      setValue(props.state.data.properties[props.propertyName] !== undefined && props.state.data.properties[props.propertyName] === 'true');
    }
  }, [props.state]);

  return (
    <div className='property_container'>
      <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        <FormControlLabel
          label={props.text}
          control={
            <Checkbox
              checked={value !== undefined}
              indeterminate={value === undefined}
              onChange={(e) => {
                if (value === undefined) {
                  setValue(true);
                  props.updateValue('true');
                }
                else if (value) {
                  setValue(false);
                  props.updateValue('false');
                }
                else if (!value) {
                  setValue(props.indeterminate ? undefined : true);
                  props.updateValue(props.indeterminate ? undefined : 'true');
                }
              }}
            />
          }
        />
      </div>
    </div>
  )
}
