import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import './property.scss';

export type StringPropertyProps = {
    minlength?: number,
    maxlength?: number,
    label: string,
    required?: boolean,
    propertyName: string,
    state: any,
    description?: string,
    updateValue: (value: string | undefined) => any
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function StringProperty(props: StringPropertyProps) {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (props.state?.data?.properties) {
     setValue(props.state.data.properties[props.propertyName])
    }
  }, [props.state]);

  return (
    <div className='property_container'>
      <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}{props.required ? ' *' : ''}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        {
          !editMode ?
            <Typography variant="body1" gutterBottom onClick={() => setEditMode(true)} component="span">
              {value?.length > 0 ? value : 'Not set'}
            </Typography>
          :
            <TextField
              sx={{ width: '100%', mb: '8px' }}
              type='text'
              size='small'
              required={props.required}
              value={value}
              error={error}
              onBlur={() => { setEditMode(false)}}
              inputRef={input => input && input.focus()}
              onChange={(e) => {
                  setError(false);
                  if (e.target.value === '' && props.required) setError(true);
                  if (props.maxlength && e.target.value.length > props.maxlength) setError(true);
                  if (props.minlength && e.target.value.length < props.minlength) setError(true);
                  setValue(e.target.value);
                  props.updateValue(e.target.value);
                }
              }
            />
        }
      </div>
    </div>
  )
}
