import {useNavigate, useParams} from 'react-router-dom';
import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import SaveIcon from "@mui/icons-material/Save";
import {Customer} from "../../api";
import {AddressEditor} from "../../components";

type CustomerState = {
  isLoading: boolean,
  data: Customer,
  hasModification: boolean
}

export function CustomerDetails() {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [state, setState] = useState<CustomerState>({
    isLoading: false,
    data: new Customer(),
    hasModification: false
  });

  const fetchData = async () => {
    if (customerId !== undefined) {
      setState(old => ({...old, isLoading: true}));
      const organization = secureLocalStorage.getItem('organization') as any;
      httpClient
        .get<Customer>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/customers/' + customerId)
        .then(response => {
          setState(old => ({...old, isLoading: false, data: response.data}));
        })
        .catch(() => {
          setState(old => ({...old, isLoading: false}));
        });
    }
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 0);
  }, []);

  const save = async () => {
    setState(old => ({...old, isLoading: true}));

    const organization = secureLocalStorage.getItem('organization') as any;

    if (customerId === undefined) {
      await httpClient
        .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/customers', JSON.stringify(state.data))
        .then(async () => {
          navigate('/customers');
        });
    } else {
      await httpClient
        .put('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/customers/' + customerId, JSON.stringify(state.data))
        .then(async () => {
          navigate('/customers');
        });
    }

    setState(old => ({...old, isLoading: false}));
  };

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          <a onClick={() => {
            navigate('/customers');
          }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
          {customerId === undefined ? "New customer" : "Edit customer"}
        </div>
        <div className="actions">
          <Button startIcon={<SaveIcon/>} onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div className="content">
        <div className="formcontainer">
          <div className={'customerBlock'}>
            Contact
          </div>
          <TextField
            required
            label={'Name'}
            variant="outlined"
            size="small"
            margin="dense"
            sx={{width: '400px'}}
            InputLabelProps={{shrink: true}}
            value={state.data?.name}
            onChange={(e) => {
              const newData = state.data;
              newData.name = e.target.value;
              setState(old => ({...old, data: newData}));
            }}
          />
          <div className={'customerContact'}>
            <TextField
              required
              label={'Primary Phone Number'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{shrink: true}}
              value={state.data?.primaryPhoneNumber}
              onChange={(e) => {
                const newData = state.data;
                newData.primaryPhoneNumber = e.target.value;
                setState(old => ({...old, data: newData}));
              }}
            />
            <TextField
              required
              label={'Primary Email'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{shrink: true}}
              value={state.data?.primaryEmail}
              onChange={(e) => {
                const newData = state.data;
                newData.primaryEmail = e.target.value;
                setState(old => ({...old, data: newData}));
              }}
            />
          </div>
          <div className={'customerBlock'}>
            Addresses
          </div>
          <div className={'customerAddresses'}>
            <AddressEditor
              title={'Billing Address'}
              address={state.data.billingAddress}
              onChange={(address) => {
                const newData = state.data;
                newData.billingAddress = address;
                setState(old => ({...old, data: newData}));
              }}/>
            <AddressEditor
              title={'Shipping Address'}
              address={state.data.shippingAddress}
              onChange={(address) => {
                const newData = state.data;
                newData.shippingAddress = address;
                setState(old => ({...old, data: newData}));
              }}/>
          </div>
        </div>
      </div>
    </div>
  );
}
