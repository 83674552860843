import {useEffect, useState, useCallback, useRef} from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridSortModel} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import AddBoxIcon from '@mui/icons-material/AddBox';
import  secureLocalStorage  from  "react-secure-storage";
import './products.scss';
import httpClient from "../../api/AxiosInstance";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {DeletePopup} from "../../components/deletePopup/DeletePopup";
import {Organization} from "../../api";

const columns: GridColDef[] = [
  {
    field: 'productId',
    headerName: 'SKU',
    width: 130,
    renderCell: (params: GridRenderCellParams) => (<Chip size="small" label={params.value} color="secondary" />)
  },
  {
    field: 'thumbnailUrl',
    headerName: 'Thumbnail',
    width: 70,
    renderCell: (params: GridRenderCellParams) => (
      <div className={'thumbnailContainer'}>
        <img className='productThumbnail' src={params.row.thumbnailAsset?.url} alt='' />
      </div>
    )
  },
  {
    field: 'properties.price',
    headerName: 'Price',
    flex: 100,
    valueGetter: (params) => { return params.row.properties?.price ?? '-' }
  },
  {
    field: 'properties.material',
    headerName: 'Material',
    flex: 100,
    valueGetter: (params) => { return params.row.properties?.material ?? '-' }
  },
  {
    field: 'properties.category',
    headerName: 'Category',
    flex: 100,
    valueGetter: (params) => { return params.row.properties?.category ?? '-' }
  },
  {
    field: 'properties.department',
    headerName: 'Department',
    flex: 100,
    valueGetter: (params) => { return params.row.properties?.department ?? '-' }
  },
  { field: 'createdAt', headerName: 'Created At', width: 130 },
  { field: 'createdBy', headerName: 'Created By', width: 130 },
  { field: 'updatedAt', headerName: 'Updated At', width: 130 },
  { field: 'updatedBy', headerName: 'Updated By', width: 130 },
];

export function Products() {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    sortBy: '',
    searchTerm: '',
    data: [],
    total: 0
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const debouncedSearch = useRef(
    debounce(async (input) => {
      setPageState(old => ({...old, page: 0, searchTerm: input}))
    }, 300)
  ).current;

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    debouncedSearch(e.target.value);
  }

  const fetchData = useCallback(async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as Organization;

    httpClient
      .get(`/api/v1/organizations/${organization.id}/environments/${organization.environments![0].id}/products`, {
        params: {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          keyword: pageState.searchTerm,
          sortBy: pageState.sortBy
        }
      })
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data.items, total: result.data.totalCount  }));
      });
  }, [paginationModel, pageState]);

  const fetchModel = useCallback(async () => {
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/productpropertiesgroups`)
      .then(result => {
        secureLocalStorage.setItem('model', result.data);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => { fetchData() }, 100);
    return () => debouncedSearch.cancel();
  }, [paginationModel.page, paginationModel.pageSize, pageState.sortBy, pageState.searchTerm]);

  useEffect(() => {
    setTimeout(() => { fetchModel() }, 100);
    return;
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setPageState(old => ({ ...old, sortBy: sortModel[0].field + ' ' + sortModel[0].sort }));
  }, []);

  const batchDelete = useCallback(async () => {
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .delete(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/products`,{
        data: {
          ids: rowSelectionModel.map(s => s)
        }
      })
      .then(() => {
        fetchData();
      });
  }, [rowSelectionModel]);

  return (
    <>
      <DeletePopup
        isVisible={showDelete}
        setIsVisible={setShowDelete}
        title={'Delete this product?'}
        message={`Are you sure you want to delete ${rowSelectionModel.length} product${rowSelectionModel.length > 1 ? 's' : ''}? This can not be undone.`}
        action={batchDelete} />
      <div className='filterContainer'>
        <div className='header'>Filters</div>
        <div className='attributefilter'>Attributes<img src='/icons/plus.svg' alt='home'/></div>
      </div>
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            Products
          </div>
          <div className="actions">
            <Button startIcon={<AddBoxIcon />} onClick={() => { navigate('/products/new'); }}>
              Add a product
            </Button>
            { rowSelectionModel.length > 0 ?
              <Button variant="contained" className='delete' color='error' size='small' startIcon={<DeleteIcon />} onClick={() => setShowDelete(true)}>
                Delete {rowSelectionModel.length} item{rowSelectionModel.length > 1 ? 's' : ''}
              </Button>
              : ''
            }
            <Paper className="productssearch">
              <InputBase id="search"
                         sx={{ ml: 1, flex: 1, height: '15px' }}
                         placeholder="Search"
                         inputProps={{ 'aria-label': 'Search' }}
                         onChange={handleSearch}
              />
              <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <div className="content">
          <DataGrid
            rows={pageState.data}
            rowCount={pageState.total}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            columns={columns}
            loading={pageState.isLoading}
            checkboxSelection
            disableColumnMenu
            density="standard"
            onRowClick={(e) => navigate(e.id.toString())}
            sortingOrder={['desc', 'asc']}
            sortingMode="server"
            hideFooterSelectedRowCount={true}
            onSortModelChange={handleSortModelChange}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'createdAt',
                    sort: 'desc'
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
