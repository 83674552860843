import './imports.scss';
import {useNavigate, useParams} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {useEffect, useState} from "react";
import httpClient from "../../api/AxiosInstance";
import {Import, JsonProblem} from "../../api";
import {ImportMapping} from "./ImportMapping";
import {ImportFailed} from "./ImportFailed";
import {ImportSucceeded} from "./ImportSucceeded";

export function ImportDetails() {
  const navigate = useNavigate();
  const { importId } = useParams();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: new Import()
  });

  useEffect(() => {
    setTimeout(() => { fetchData() }, 100);
    return;
  }, []);

  const fetchData = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get<Import>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/imports/${importId}`)
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data }));
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response) as JsonProblem;
        alert(errorMessage.type);
      });
  }

  return (
    <>
        {
          pageState?.data?.status === undefined ?
            <>Loading...</> :
          (() => {
            switch(pageState.data.status?.toLowerCase()) {
              case 'failed': return <ImportFailed model={pageState.data} />;
              case 'pending': return <ImportMapping model={pageState.data} />;
              case 'succeeded': return <ImportSucceeded model={pageState.data} />;
              default: return <>Unknown import status: {pageState.data.status}</>
            }
          })()
        }
    </>
  );
}
