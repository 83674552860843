import Typography from '@mui/material/Typography';
import './propertygroup.scss';

export type PropertyGroupProps = {
  panelId: string,
  label: string,
  children: JSX.Element | JSX.Element[]
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PropertyGroup(props: PropertyGroupProps) {

  return (
    <div className='group'>
      <div className='header groupheader'>
          {props.label}
      </div>
      {props.children}
    </div>
  )
}
