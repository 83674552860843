import {LeftMenu, PublicRibbon, Ribbon} from '../components';
import "./layout.scss"
import {Outlet} from "react-router-dom";

export const PublicPageLayout = () => {

  return (
    <div className='app-frame '>
      <div className='content-frame'>
        <div className='ribbon'>
          <PublicRibbon/>
        </div>
        <div className='app-content'>
          <Outlet/>
        </div>
      </div>
    </div>
  );
};
