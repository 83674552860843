import {
  NumberProperty,
  StringProperty,
  BooleanProperty,
  UrlProperty,
  MultiselectProperty,
  PropertyGroup
} from '../../components';
import './productdetails.scss';
import secureLocalStorage from "react-secure-storage";
import {useEffect, useState} from "react";
import { HtmlProperty } from '../../components/formElement/HtmlProperty';

export function ProductProperties({productState}) {
  const [state, setState] = useState(productState);
  const model = secureLocalStorage.getItem('model') as any;

  return (
    <>
      {
        model.items.map((group, idx) => (
          <PropertyGroup key={idx} panelId={idx} label={group.name}>
            {
              group.properties.map(prop => {

                if (prop?.type === 'String') {
                  return (
                    <StringProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      minlength={prop.parameters['minlength'] ? Number(prop.parameters['minlength']) : undefined}
                      maxlength={prop.parameters['maxlength'] ? Number(prop.parameters['maxlength']) : undefined}
                      required={prop.parameters['required'] ? Boolean(prop.parameters['required']) : false} />
                  )
                } else if (prop?.type === 'Html') {
                  return (
                    <HtmlProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      minlength={prop.parameters['minlength'] ? Number(prop.parameters['minlength']) : undefined}
                      maxlength={prop.parameters['maxlength'] ? Number(prop.parameters['maxlength']) : undefined}
                      required={prop.parameters['required'] ? Boolean(prop.parameters['required']) : false} />
                  )
                } else if (prop?.type === 'Number') {
                  return (
                    <NumberProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      min={prop.parameters['min'] ? Number(prop.parameters['min']) : undefined}
                      max={prop.parameters['max'] ? Number(prop.parameters['max']) : undefined}
                      required={prop.parameters['required'] ? Boolean(prop.parameters['required']) : false} />
                  )
                } else if (prop?.type === 'Boolean') {
                  return (
                    <BooleanProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      text={prop.parameters['text'] ? prop.parameters['text'] : undefined}
                      indeterminate={prop.parameters['indeterminate'] ? Boolean(prop.parameters['indeterminate']) : false} />
                  )
                } else if (prop?.type === 'Url') {
                  return (
                    <UrlProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      required={prop.parameters['required'] ? Boolean(prop.parameters['required']) : false} />
                  )
                } else if (prop?.type === 'Multiselect') {
                  return (
                    <MultiselectProperty
                      key={prop.name}
                      label={prop.name}
                      description={prop.description}
                      state={productState}
                      propertyName={prop.code}
                      updateValue={(e) => {
                        if (productState.data) {
                          const newData = productState.data;
                          newData.properties[prop.code] = e;
                          setState(old => ({ ...old, data: newData }));
                        }
                      }}
                      required={true} />
                  )
                } else {
                  return (
                    <>{prop?.type}: no implementation</>
                  )
                }
              })
            }
          </PropertyGroup>
        ))
      }
     </>
  );
}
