import Typography from '@mui/material/Typography';

import './placeholder.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Placeholder() {

  return (
    <>
      <div className="placeholdercontainer">
        <div className='container'>
          <img src='/logo.png' alt='logo' className='smalllogoimg' />
          <Typography variant="h3" gutterBottom color='#183C59'>Work In Progress</Typography>
          <Typography variant="h6" gutterBottom color='#FD9367'>There is nothing there for now...</Typography>
        </div>
      </div>
    </>
  );
}
