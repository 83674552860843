import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import {Organization, UserProfile} from "../../api";
import {Button, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";

function useFilePreview(file: Blob) : string | undefined {
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file && file[0]) {
      const newUrl = URL.createObjectURL(file[0]);

      if (newUrl !== imgSrc) {
        setImgSrc(newUrl);
      }
      else {
        setImgSrc(undefined);
      }
    }
  }, [file]);

  return imgSrc;
}

export function RegisterOrganization() {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const { register, handleSubmit, watch } = useForm();

  const file = watch(["file"]);
  const filePreview = useFilePreview(file[0]);

  const fetchData = async () => {
    const profile = secureLocalStorage.getItem('profile') as UserProfile;
    if (profile.organizations === undefined || profile.organizations.length === 0) {
      setShowForm(true);
      return;
    }

    secureLocalStorage.setItem('organization', profile.organizations[0].organization!);
    navigate('/home');
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 100);
    return;
  }, []);

  const onSubmit2 = async (data) => {

    const profile = secureLocalStorage.getItem('profile') as UserProfile;

    const formData = new FormData();
    formData.append("file", data.file[0]);
    data = { ...data, file: data.file[0]?.name };
    formData.append("model", JSON.stringify(data));

    await httpClient
      .post<Organization>('/api/v1/organizations', formData)
      .then(res => secureLocalStorage.setItem('organization', res.data))
      .then(async () => await httpClient.get<UserProfile>("/api/v1/users/" + profile.id))
      .then((profile) => secureLocalStorage.setItem('profile', profile.data))
      .then(() => navigate('/home'));
  };

  return (
    <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      {!showForm ?
        <div style={{fontSize:'1.1em', paddingTop:'12px', paddingBottom:'12px'}}>
          Loading your organization information...
        </div>
        :
        <>
          <div style={{fontSize:'1.3em', paddingTop:'12px'}}>
            Welcome to the JABS community!
          </div>
          <div style={{fontSize:'1.1em', paddingTop:'12px', paddingBottom:'12px'}}>
            Now, let's create your organization.
          </div>
          <form className="formcontainer" onSubmit={handleSubmit(onSubmit2)}>
            <TextField
              required
              label='Name'
              variant="outlined"
              size="small"
              margin="dense"
              {...register("name")}
              sx={{width: '400px'}}
            />
            <div className="thumbnailContainer">
              <Button
                variant="text"
                component="label">
                Logo
                <input
                  {...register("file")}
                  type="file"
                  hidden
                />
              </Button>

              {filePreview ? <img src={filePreview} alt="preview" className='thumbnail'/> : null}
            </div>
            <LoadingButton type={"submit"} variant="contained" sx={{mt: 2, mb: 1, width: '400px'}}>
              Save
            </LoadingButton>
          </form>
        </>
      }
    </div>
  );
}
