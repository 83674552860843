import {TimeSpan} from "./TimeSpan";

export class ResultPage<T> {
  items: T[]
  page: number
  itemPerPage: number
  totalCount: number
}

export class UserProfile {
  id: string
  firstname: string | undefined
  lastname: string | undefined
  email: string | undefined
  phone: string | undefined
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  profileCompleted: boolean
  organizations: UserOrganization[] | undefined
}

export class UserOrganization {
  organization: Organization | undefined
}

export class Organization {
  id: string
  name: string | undefined
  logoUrl: string | undefined
  billingAddress: Address | undefined
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  environments: Environment[] | undefined
}

export class Environment {
  id: string
  name: string | undefined
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
}

export class Address {
  street: string | undefined
  zipCode: string | undefined
  city: string | undefined
  stateOrProvince: string | undefined
  country: string | undefined
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
}

export class Property {
  id: string
  unit: PropertyUnit | undefined
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  unitId: string | undefined
  name: string
  code: string
  description: string | undefined
  type: string | undefined
  parameters: {[id: string] : string}
  includedInAi: boolean
}

export class PropertyUnit {
  id: string
  name: string
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  unit: string
  description: string
}

export class PropertyGroup {
  id: string
  name: string
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  description: string
  order: number
  properties: PropertyUnit[] | undefined
  propertyids: string[] | undefined
}

export class Asset {
  id: string
  url: string
  originalFilename: string
  status: string
  visibility: string
}

export class Note {
  id?: string
  content: string
  requireAction?: boolean
  dueDate?: Date
  actionDone?: boolean
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
}

export class Dashboard {
  products: any
  assets: any
  customers: any
}

export class CustomerAddress {
  street: string
  zipCode: string
  city: string
  stateOrProvince: string
  country: string
}

export class Customer {
  name: string
  primaryEmail: string | undefined
  primaryPhoneNumber: string | undefined
  billingAddress: CustomerAddress | undefined
  shippingAddress: CustomerAddress | undefined
}

export class Import {
  id: string
  createdAt: Date
  createdBy: string
  updatedAt: Date | undefined
  updatedBy: string | undefined
  parser: string | undefined
  status: string | undefined
  step: string | undefined
  mode: string | undefined
  title: string | undefined
  mapping: ImportMapping | undefined
  errors: ImportValidationError[] | undefined
  duplicates: ImportDuplicateError[] | undefined
  statistics: ImportStatistics | undefined
}

export class ImportMapping {
  isValid: boolean | undefined
  columns: ImportPropertyMapping[] | undefined
}

export class ImportPropertyMapping {
  columnName: string
  ignoreColumn: boolean
  propertyId: string | undefined
  propertyName: string | undefined
  propertyType: string | undefined
}

export class ImportValidationError {
  id: string
  line: number | undefined
  description: string | undefined
  columnName: string | undefined
  value: string | undefined
  expectedType: string | undefined
}

export class ImportDuplicateError {
  id: string
  lines: number[] | undefined
  value: string | undefined
  description: string | undefined
}

export class ImportStatistics {
  totalRows: number | undefined
  totalProcessableRows: number | undefined
  addedProducts: number | undefined
  updatedProducts: number | undefined
  fileLoadingDuration: TimeSpan | undefined
  validationDuration: TimeSpan | undefined
  importDuration: TimeSpan | undefined
  mergeDuration: TimeSpan | undefined
  cleanupDuration: TimeSpan | undefined
}

export class JsonProblem {
  type: string | undefined
  title: string | undefined
  status: number | undefined
}

export class ApiChannel {
  id: string
  name: string
  description: string | undefined
  type: string
  status: string
}

export class StoreChannel {
  id: string
  name: string
  title: string
  description: string | undefined
  type: string
  status: string
}

export class EmailChannel {
  id: string
  name: string
  description: string | undefined
  type: string
  status: string
}

export class ChannelDto {
  apis: ApiChannel[]
  stores: StoreChannel[]
  emails: ApiChannel[]
}
