import './imports.scss';
import {useNavigate} from "react-router-dom";

export function ImportFailed({model}) {
  const navigate = useNavigate();

  return (
    <>
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            <a onClick={() => {
              navigate('/imports');
            }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
            Oops, import failed!
          </div>
        </div>
        <div className="content">
          <div className="errorContent">
            {model.duplicates && model.duplicates.length > 0 &&
              <>
                <div className={'errorTitle'}>Duplicate IDs</div>
                <ul>
                  {model.duplicates?.map((duplicate) => (
                    <>
                      <li>{duplicate.value} :</li>
                      <ul>
                        {duplicate.lines.map((line) => (
                          <li>Line {line}</li>
                        ))}
                      </ul>
                    </>
                  ))}
                </ul>
              </>
            }
            {model.errors && model.errors.length > 0 &&
              <>
                <div className={'errorTitle'}>Type Validation errors</div>
                <ul>
                  {model.errors?.map((error) => (
                    <li>Line {error.line}, column {error.columnName} : {error.value} is not a
                      valid {error.expectedType}</li>
                  ))}
                </ul>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}
