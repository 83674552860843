import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";

let connections = {} as { [key: string]: {type: string; connection: HubConnection; started: boolean}};

function createConnection(connectionKey: string = 'default') {
  const existing = connections[connectionKey];
  if (existing) {
    return existing.connection;
  }

  const conn = new HubConnectionBuilder()
    .withUrl((process.env.REACT_APP_WEBSOCKET_URL ?? ''), {
      logger: LogLevel.Information,
      withCredentials: false
    })
    .withAutomaticReconnect()
    .build();

  connections[connectionKey] = {
    type: connectionKey,
    connection: conn,
    started: false
  }

  return conn;
}

function startConnection(channelId: string, connectionKey: string = 'default') {
  const existing = connections[connectionKey];
  if (!existing.started) {
    existing.connection
      .start()
      .then(() => {
        existing.connection.invoke('join', channelId);
      })
      .catch((err) => console.error('WEBSOCKET: ' + err.toString()));
    existing.started = true;
  }
}

function stopConnection(connectionKey: string = 'default') {
  const existing = connections[connectionKey];
  if (!existing.started) {
    existing.connection
      .stop()
      .catch((err) => console.error('WEBSOCKET: ' + err.toString()));
    existing.started = false;
  }
}

function registerOnServerEvents(channelId: string, connectionKey: string = 'default', callback: (payload: any) => void) {
  try {
    const conn = createConnection(connectionKey);
    conn.on('UpdateImport', (payload: any) => {
      callback(payload);
    });

    conn.onreconnected(() => {
      conn.invoke('join', channelId);
    });

    conn.onclose(() => {
      stopConnection(connectionKey);
    });

    startConnection(channelId, connectionKey);
  } catch (error) {
    console.error('WEBSOCKET: ' + error);
  }
}

export const socketService = {
  registerOnServerEvents,
  stopConnection
}
