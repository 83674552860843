import {useEffect, useState, useCallback, useRef, ChangeEvent} from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {DataGrid, GridColDef, GridRowSelectionModel, GridSortModel} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import AddBoxIcon from '@mui/icons-material/AddBox';
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {DeletePopup} from "../../components/deletePopup/DeletePopup";

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150
  },
  {
    field: 'primaryEmail',
    headerName: 'Primary Email',
    width: 150
  },
  {
    field: 'primaryPhoneNumber',
    headerName: 'Primary Phone Number',
    flex: 1
  }
];

export function Customers() {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    sortBy: '',
    searchTerm: '',
    data: [],
    total: 0
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const debouncedSearch = useRef(
    debounce(async (input) => {
      setPageState(old => ({...old, page: 0, searchTerm: input}))
    }, 300)
  ).current;

  async function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    debouncedSearch(e.target.value);
  }

  const fetchData = useCallback(async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/customers', {
        params: {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          keyword: pageState.searchTerm,
          sortBy: pageState.sortBy
        }
      })
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data.items, total: result.data.totalCount  }));
      });
  }, [paginationModel, pageState]);

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 100);
    return () => debouncedSearch.cancel();
  }, [paginationModel.page, paginationModel.pageSize, pageState.sortBy, pageState.searchTerm]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel[0] !== undefined) {
      setPageState(old => ({...old, sortBy: sortModel[0]?.field + ' ' + sortModel[0]?.sort}));
    }
  }, []);

  return (
    <>
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            Customers
          </div>
          <div className="actions">
            <Button startIcon={<AddBoxIcon/>} onClick={() => {
              navigate('/customers/new');
            }}>
              New customer
            </Button>
            <Paper className="productssearch">
              <InputBase id="search"
                         sx={{ml: 1, flex: 1, height: '15px'}}
                         placeholder="Search"
                         inputProps={{'aria-label': 'Search'}}
                         onChange={handleSearch}
              />
              <IconButton sx={{p: '10px'}} aria-label="search">
                <SearchIcon/>
              </IconButton>
            </Paper>
          </div>
        </div>
        <div className="content">
          <DataGrid
            rows={pageState.data}
            rowCount={pageState.total}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            columns={columns}
            loading={pageState.isLoading}
            checkboxSelection
            disableColumnMenu
            hideFooterSelectedRowCount={true}
            density="compact"
            onRowClick={(e) => navigate(e.id.toString())}
            sortingOrder={['desc', 'asc']}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'createdAt',
                    sort: 'desc'
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
