import {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Button, Drawer,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import './productdetails.scss';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ProductProperties } from './ProductProperties';
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import {AssetSelector} from "../../components/assetSelector/AssetSelector";
import {DeletePopup} from "../../components/deletePopup/DeletePopup";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Note} from "../../api";

export type ProductDetailState = {
  isLoading: boolean,
  data: ProductDefinition | undefined,
  files: any,
  hasModification: boolean
}

export class PriceDefinition {
  price: number;
}

export class InventoryDefinition {
  quantity: number;
}

export class ProductDefinition {
  id: number;
  properties: { [id: string] : any; };
  label: string;
  productId: string;
  thumbnailAsset: any;
  assets: ProductAssetDefinition[];
  price:PriceDefinition | undefined;
  inventory: InventoryDefinition | undefined;
  thumbnailAssetId: string | undefined
  constructor() {
    this.properties = {};
  }
}

export class ProductAssetDefinition {
  id: number;
  name: string;
  url: string;
  status: string;
  visibility: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  properties: { [id: string] : any; };
  constructor() {
    this.properties = {};
  }
}

export function ProductDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productId } = useParams();
  const [showAssetSelector, setShowAssetSelector] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [note, setNote] = useState(new Note());
  const [notes, setNotes] = useState(Array<Note>);
  const [state, setState] = useState<ProductDetailState>({
    isLoading: false,
    data: new ProductDefinition(),
    files: undefined,
    hasModification: false
  });

  useEffect(() => {
    const isNew = productId === undefined || productId === 'new';
    setState(old => ({ ...old, newProduct: isNew }));
    if (!isNew) {
      setTimeout(async () => { await fetchData() }, 0);
    }
  }, []);

  const fetchData = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId)
      .then(response => {
        if (response.data.properties === undefined) response.data.properties = {};
        setState(old => ({ ...old, isLoading: false, data: response.data }));
      })
      .catch(() => {
        setState(old => ({ ...old, isLoading: false, data: undefined }));
        toast.error(t('product.load.error'))
      });
  }

  const save = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    const result = await httpClient.put('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId, JSON.stringify(state.data));
    setState(old => ({ ...old, isLoading: false, data: result.data }));
    toast.success(t('product.save.updated'))
  }

  const saveNote = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId + '/notes', JSON.stringify(note))
      .then(async result => {
        await fetchNotes();
        note.content = '';
        setNote(note);
      })
      .finally(() => {
        setState(old => ({ ...old, isLoading: false }));
      });
  }

  const fetchNotes = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId + '/notes')
      .then(result => {
        console.warn(result.data.items);
        setNotes(result.data.items);
      })
      .finally(() => {
        setState(old => ({ ...old, isLoading: false }));
      });
  }

  useEffect(() => {
    if (showNotes) {
      setTimeout(async () => { await fetchNotes() }, 0);
    }
  }, [showNotes]);


  const fetchChanges = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId + '/changes')
      .then(result => {
        console.warn(result.data.items);
        setNotes(result.data.items);
      })
      .finally(() => {
        setState(old => ({ ...old, isLoading: false }));
      });
  }

  useEffect(() => {
    if (showHistory) {
      setTimeout(async () => { await fetchChanges() }, 0);
    }
  }, [showHistory]);

  const deleteProduct = async () => {
    setState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    const result = await httpClient.delete('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + productId);
    setState(old => ({ ...old, isLoading: false, data: result.data }));
    navigate('/products');
  }

  return (
    <>
      <AssetSelector
        open={showAssetSelector}
        showExisting={true}
        onClose={() => setShowAssetSelector(false)}
        productId={productId}
        onSelect={assets => {
          setShowAssetSelector(false);
          const newData = state.data!;
          newData.thumbnailAsset = assets[0];
          newData.thumbnailAssetId = assets[0].id;
          setState(old => ({...old, data: newData}));
        }}
        maxFiles={1} />
      <Drawer
        key={'changes'}
        anchor={'right'}
        open={showHistory}
        onClose={() => setShowHistory(false)}
      >
        History
      </Drawer>
      <Drawer
        key={'right'}
        anchor={'right'}
        open={showNotes}
        onClose={() => setShowNotes(false)}
      >
        <div className={'notesContainer'}>
          <div className={'notesListContainer'}>
            {notes?.map(innerNote =>
              <div className={'note'} dangerouslySetInnerHTML={{__html: innerNote.content}}/>
            )}
        </div>
        <div className={'editorContainer'}>
            <ReactQuill
              theme="snow"
              value={note.content}
              onChange={(value) => {
                note.content = value;
                setNote(note);
              }} />
            <Button startIcon={<SaveIcon/>} variant={'contained'} size={'small'} className={'saveBtn'} onClick={saveNote}>
              Save
            </Button>
          </div>
        </div>
      </Drawer>
      <DeletePopup
        isVisible={showDelete}
        setIsVisible={setShowDelete}
        title={'Delete this product?'}
        message={'Are you sure you want to delete this product? This can not be undone.'}
        action={deleteProduct} />
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            <a onClick={() => {
              navigate('/products');
            }}><img className={'backBtn'} src='/icons/back.svg' alt='back' /></a>
            <input
              type='text'
              value={state?.data?.productId}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.productId = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}/>
          </div>
          <div className="actions">
            <Button startIcon={<EditNoteIcon/>} onClick={() => setShowHistory(true)}>
              Changes
            </Button>
            <Button startIcon={<EditNoteIcon/>} onClick={() => setShowNotes(true)}>
              Notes
            </Button>
            <Button startIcon={<SaveIcon/>} onClick={save}>
              Save
            </Button>
            <Button variant="contained" className='delete' color='error' size='small' startIcon={<DeleteIcon/>} onClick={() => setShowDelete(true)}>
              Delete
            </Button>
          </div>
        </div>
        <div className='productdetail'>
          <div className='productIdContainer'>
            <div className='thumbnailcontainer'>
              {state?.data?.thumbnailAsset?.url ? <img src={state?.data?.thumbnailAsset?.url} alt='thumbnail'/> :
                <div></div>}
              <div className='thumbnailactions'>
                <IconButton size={'small'}>
                  <DeleteIcon onClick={() => {
                    const newData = state.data!;
                    newData.thumbnailAsset = undefined;
                    newData.thumbnailAssetId = undefined;
                    setState(old => ({...old, data: newData}));
                  }}/>
                </IconButton>
                <IconButton size={'small'}>
                  <UploadIcon onClick={() => setShowAssetSelector(true)}/>
                </IconButton>
              </div>
            </div>
            <div className='field'>Label<input
              type='text'
              value={state?.data?.label}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.label = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}/></div>
            <div className='field'>Price<input
              type='text'
              value={state?.data?.price?.price}/></div>
            <div className='field'>Inventory<input
              type='text'
              value={state?.data?.inventory?.quantity}/></div>
          </div>
          <div className='rightcolumn'>
            <ProductProperties productState={state}/>
          </div>
        </div>
      </div>
    </>
  );
}
