import {Navigate, Outlet} from "react-router-dom";
import * as React from "react";
import secureLocalStorage from "react-secure-storage";
import {UserProfile} from "./datatype";

export function OrganizationGuard() {
  const profile = secureLocalStorage.getItem('profile') as UserProfile;

  if (profile.organizations === undefined || profile.organizations.length === 0) {
    return <Navigate to={'/register/organization'}/>;
  }

  secureLocalStorage.setItem('organization', profile.organizations[0].organization!);
  return <Outlet />;
}
