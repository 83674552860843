import './imports.scss';
import secureLocalStorage from "react-secure-storage";
import {useState} from "react";
import httpClient from "../../api/AxiosInstance";
import {Import, JsonProblem} from "../../api";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";

export function ImportMapping({model}) {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    isLoading: false
  });

  const refreshMapping = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .put<Import>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/imports/${model.id}/mapping`)
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data }));
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response) as JsonProblem;
        alert(errorMessage.type);
      });
  }

  const generateMapping = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .post<Import>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/imports/${model.id}/mapping`)
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data }));
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response) as JsonProblem;
        alert(errorMessage.type);
      });
  }

  const startImport = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .post<Import>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/imports/${model.id}/start`)
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data }));
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.request.response) as JsonProblem;
        alert(errorMessage.type);
      });
  }

  return (
    <>
      <div className='contentContainer'>
        <div className='header'>
          <div className='title'>
            <a onClick={() => {
              navigate('/imports');
            }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
            Import Mapping
          </div>
        </div>
        <div className={'content noscroll'}>
          <div className={'importContainer'}>
            <div className={'importprops'}>
              {
                model?.mapping?.columns?.map(column => {
                  if (column.propertyName === undefined) {
                    return (
                      <div className={'notmapped'}>
                        <div className={'mapping_title'}>{column.columnName} is NOT MAPPED</div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={'mapped'}>
                        <div className={'mapping_title'}>{column.columnName} =&gt; {column.propertyName}</div>
                        <div className={'mapping_type'}>{column.propertyType}</div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
          <div className='importActions'>
            {
              model?.mapping?.isValid === false &&
              <>
                <LoadingButton type={"submit"} variant="outlined" sx={{mb: 1, width: '200px'}} onClick={refreshMapping}>
                  Refresh Mapping
                </LoadingButton>
                <LoadingButton type={"submit"} variant="outlined" sx={{mb: 1, width: '200px'}}
                               onClick={generateMapping}>
                  Help Me Map!
                </LoadingButton>
              </>
            }
            <LoadingButton type={"submit"} variant="contained" sx={{mb: 1, width: '200px'}} onClick={startImport}>
              Start Import
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
}
