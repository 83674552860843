import { Routes, Route } from 'react-router-dom';
import {
  Landing,
  Unauthorized,
  Products,
  ProductDetails,
  NotFound,
  Properties,
  PropertyUnits,
  PropertyUnitDetails,
  RegisterAccount,
  PropertyGroups,
  ListImports,
  ImportFile,
  Home,
  Placeholder,
  AddProperties,
  CreateProduct,
  Assets,
  PropertyGroupDetails,
  Customers,
  CustomerDetails,
  Login,
  RegisterOrganization,
  ImportDetails,
  Channels
} from './pages';
import {MenuPageLayout, PageLayout, PublicPageLayout} from './layout';
import {PrivateRoute} from "./api/ProtectedRoute";
import {ProfileGuard} from "./api/ProfileGuard";
import {OrganizationGuard} from "./api/OrganizationGuard";

export default function AppRoutes() {

  return (
    <Routes>
      <Route element={<PublicPageLayout/>}>
        <Route path={'/'} element={<Landing/>} />
        <Route path={'/login'} element={<Login/>} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<ProfileGuard />}>
          <Route element={<OrganizationGuard />}>
            <Route element={<MenuPageLayout/>}>
              <Route path={'/customers'} element={<Customers/>} />
              <Route path={'/customers/new'} element={<CustomerDetails/>} />
              <Route path={'/customers/:customerId'} element={<CustomerDetails/>} />
              <Route path={'/settings/properties'} element={<Properties/>} />
              <Route path={'/settings/properties/new'} element={<AddProperties/>} />
              <Route path={'/settings/properties/:propertyId'} element={<AddProperties/>} />
              <Route path={'/settings/properties/units'} element={<PropertyUnits/>} />
              <Route path={'/settings/properties/units/new'} element={<PropertyUnitDetails/>} />
              <Route path={'/settings/properties/units/:propertyUnitId'} element={<PropertyUnitDetails/>} />
              <Route path={'/settings/properties/groups'} element={<PropertyGroups/>} />
              <Route path={'/settings/properties/groups/new'} element={<PropertyGroupDetails/>} />
              <Route path={'/settings/properties/groups/:propertyGroupId'} element={<PropertyGroupDetails/>} />
              <Route path={'/settings'} element={<Placeholder/>} />
              <Route path={'/home'} element={<Home/>} />
              <Route path={'/products'} element={<Products/>} />
              <Route path={'/assets'} element={<Assets/>} />
              <Route path={'/products'} element={<Products/>} />
              <Route path={'/products/new'} element={<CreateProduct/>} />
              <Route path={'/products/:productId'} element={<ProductDetails/>} />
              <Route path={'/imports'} element={<ListImports/>} />
              <Route path={'/imports/new'} element={<ImportFile/>} />
              <Route path={'/imports/:importId'} element={<ImportDetails/>} />
              <Route path={'/assets'} element={<Assets/>} />
              <Route path={'/channels'} element={<Channels/>} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<PageLayout/>}>
          <Route path={'/register/account'} element={<RegisterAccount/>} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<ProfileGuard />}>
          <Route element={<PageLayout/>}>
            <Route path={'/register/organization'} element={<RegisterOrganization/>} />
          </Route>
        </Route>
      </Route>
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
