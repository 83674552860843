import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './publicribbon.scss';
import LoadingButton from "@mui/lab/LoadingButton";
import {useAuth0} from "@auth0/auth0-react";

export function PublicRibbon() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();



  return (
    <div className="publicribbon">
      <div className="logocontainer">
        <img src='/logo.png' alt='logo' className='logoimg' />
      </div>
      <div className="ribbonmenu">
        <Button variant="text" onClick={() => { navigate('/about'); }}>
          {t('ribbon.about')}
        </Button>
        <Button variant="text" onClick={() => { navigate('/features'); }}>
          {t('ribbon.features')}
        </Button>
        <Button variant="text" onClick={() => { navigate('/pricing'); }}>
          {t('ribbon.pricing')}
        </Button>
        <LoadingButton variant="outlined" onClick={() => loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
            audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
            scope: "openid profile email",
            screen_hint: 'signup',
            organization: undefined
          }})}>
          Signup
        </LoadingButton>
        <LoadingButton variant="contained" onClick={() => navigate('/home')}>
          {t('ribbon.login')}
        </LoadingButton>
      </div>
    </div>
  );
}
