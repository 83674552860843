import {useEffect, useState, useCallback} from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import {ChannelDto} from "../../api";

export function Channels() {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: new ChannelDto()
  });

  const fetchData = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get<ChannelDto>(`/api/v1/organizations/${organization.id}/environments/${organization.environments[0].id}/channels`)
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data }));
      });
  };

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 0);
    return;
  }, []);

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          Channels
        </div>
      </div>
      <div className="content">
        <div className="channelcontent">
          <div className={'channelgroupdesc'}>
            <b>Channels are the way to reach your customers out.</b><br />
            With Store channels, you will offer your customers an online transactional store. APIs channels will allow your customers to integrate your transactional platform directly within their own systems. Emails channels will be very useful to connect with customers with less technical knowledge.
          </div>
          <div className={'channelgroup'}>Stores</div>
          {
            pageState.data?.stores ?
              <ul>
                {pageState.data.stores.map((store) =>
                  <li>{store.name}</li>
                )}
              </ul>
            :
              <div>No Store channel</div>
          }
          <div className={'channelgroup'}>APIs</div>
          {
            pageState.data?.apis ?
              <ul>
                {pageState.data.apis.map((api) =>
                  <li>{api.name}</li>
                )}
              </ul>
            :
              <div>No API channel</div>
          }
          <div className={'channelgroup'}>Emails</div>
          {
            pageState.data?.emails ?
              <ul>
                {pageState.data.emails.map((email) =>
                  <li>{email.name}</li>
                )}
              </ul>
            :
              <div>No Email channel</div>
          }
        </div>
      </div>
    </div>
  );
}
