import "./layout.scss"
import {useState} from "react";
import {Box, Menu, MenuItem, Toolbar} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {AccountButton} from "../components";
import Button from "@mui/material/Button";

export const MenuPageLayout = () => {
  const navigate = useNavigate();
  const [anchor1, setAnchor1] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchor1);
  const [anchor2, setAnchor2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchor2);
  const [anchor3, setAnchor3] = useState<null | HTMLElement>(null);
  const open3 = Boolean(anchor3);

  const navigateTo = (dest: string) => {
    setAnchor1(null);
    setAnchor2(null);
    setAnchor3(null);
    navigate(dest);
  };

  return (
    <div className='app-frame '>
      <div className='content-frame'>
        <div className='ribbon'>
          <Toolbar>
            <Box sx={{flexGrow: 0}}>
              <div className="ribbontoplogocircle">
                <a onClick={() => navigateTo('/home')} className="ribbontoplogocircle">
                  <img className="ribbonmenuimage" src="/logo.png" alt={'logo'}/>
                </a>
              </div>
            </Box>
            <Box sx={{flexGrow: 1}} style={{paddingLeft: '36px', display:'flex', gap:'12px'}}>
              {/* <Button
                id="basic-button"
                onClick={() => navigateTo('/home')}
                style={{color:'#ffffff'}}
              >
                Dashboard
              </Button> */}
              <Button
                id="basic-button"
                style={{color:'#ffffff'}}
                aria-controls={open1 ? 'basic-menu1' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={evt => setAnchor1(evt.currentTarget)}
              >
                Products
              </Button>
              <Menu
                id="basic-menu1"
                anchorEl={anchor1}
                open={open1}
                onClose={() => setAnchor1(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigateTo('/products')}>Products</MenuItem>
                <MenuItem onClick={() => navigateTo('/assets')}>Assets</MenuItem>
                <MenuItem onClick={() => navigateTo('/imports')}>Imports</MenuItem>
              </Menu>
              <Button
                id="basic-button"
                style={{color:'#ffffff'}}
                onClick={() => navigateTo('/customers')}
              >
                Customers
              </Button>
              <Button
                id="basic-button"
                style={{color:'#ffffff'}}
                onClick={() => navigateTo('/channels')}
              >
                Channels
              </Button>
              {/* <Menu
                id="basic-menu2"
                anchorEl={anchor2}
                open={open2}
                onClose={() => setAnchor2(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigateTo('/channels')}>Channels</MenuItem>
                <MenuItem onClick={() => navigateTo('/customers')}>Customers</MenuItem>
              </Menu> */}
              <Button
                id="basic-button"
                style={{color:'#ffffff'}}
                aria-controls={open3 ? 'basic-menu3' : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? 'true' : undefined}
                onClick={evt => setAnchor3(evt.currentTarget)}
              >
                Settings
              </Button>
              <Menu
                id="basic-menu3"
                anchorEl={anchor3}
                open={open3}
                onClose={() => setAnchor3(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigateTo('/settings/properties/units')}>Units</MenuItem>
                <MenuItem onClick={() => navigateTo('/settings/properties')}>Properties</MenuItem>
                <MenuItem onClick={() => navigateTo('/settings/properties/groups')}>Groups</MenuItem>
                <MenuItem onClick={() => navigateTo('/settings/properties/families')}>Families</MenuItem>
              </Menu>
            </Box>
            <Box sx={{flexGrow: 0}}>
              <AccountButton/>
            </Box>
          </Toolbar>
        </div>
        <div className='app-content'>
          <Outlet/>
        </div>
      </div>
    </div>
  );
};
