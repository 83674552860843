import style from '../styles/base/variables.module.scss';
import { createTheme } from '@mui/material/styles';
import '../styles/global.scss';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          paddingBottom: '.7em',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      }
    },
  },
  breakpoints: {
    values: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440
    }
  },
  palette: {
    common: {
      black: style['basic-darkest'],
      white: style['basic-brightest']
    },
    background: {
      paper: style['basic-brightest'],
      default: style['basic-gray98']
    },
    primary: {
      light: style['primary-light'],
      main: style['primary-main'],
      dark: style['primary-dark'],
      contrastText: style['basic-brightest']
    },
    secondary: {
      light: style['secondary-light'],
      main: style['secondary-main'],
      dark: style['secondary-dark'],
      contrastText: style['basic-brightest']
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    }
  }
});

export default theme;
