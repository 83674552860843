import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from "@mui/material";
import Button from "@mui/material/Button";
import {forwardRef, useEffect, useMemo, useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {useDropzone} from "react-dropzone";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import {Asset, ResultPage} from "../../api";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import './assetSelector.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  height: '50px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  justifyContent: 'center'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export type AssetSelectorProps = {
  open: boolean;
  onClose: () => any;
  onSelect: (file: Asset[]) => any;
  maxFiles?: number;
  showExisting?: boolean;
  productId?: string;
}

export function AssetSelector(props: AssetSelectorProps) {
  const [files, setFiles] = useState<(File & {preview:string})[]>([]);
  const [assets, setAssets] = useState<Asset[]>();
  const [showAllAssets, setShowAllAssets] = useState<boolean>(false);

  const closeSelector = () => {
    props.onClose();
    setFiles([]);
  };
  const selectFiles = async () => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const files = await save();
      props.onSelect(files);
    }
    closeSelector();
  };

  const save = async () => {
    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append("files", file)
    })

    const organization = secureLocalStorage.getItem('organization') as any;
    return await httpClient
      .post<Asset[]>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/assets', formData)
      .then(result => {
        toast.success('Assets stored!');
        return result.data;
      });
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'image/*': []},
    maxFiles: props.maxFiles ?? undefined,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    if (props.open && props.showExisting) {
      setTimeout(async () => { await fetchData() }, 0);
    }
  }, [props.open, props.showExisting, showAllAssets]);

  const fetchData = async () => {
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get<ResultPage<Asset>>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/assets', {
      params: {
        productId: showAllAssets ? undefined : props.productId
      }
    })
      .then(response => {
        setAssets(response.data.items);
      });
  }

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt={file.name}
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeSelector}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}>
        <DialogTitle>Asset selection</DialogTitle>
        <DialogContent>
          <div className={'assetcontent'}>
            <div className={'assetdropzone'}>
              <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Drag and drop assets here, or click to select files</p>
              </div>
            </div>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
            { assets ?
              <>
                <hr />
                <div>
                  <FormControlLabel
                    label={'Show all assets'}
                    control={
                      <Checkbox
                        checked={showAllAssets}
                        onChange={(e) => {
                          setShowAllAssets(e.target.checked);
                        }}
                      />}
                    />
                </div>
                <div>
                  {assets.map(asset =>
                    <div style={thumb} key={asset.id}>
                      <div style={thumbInner}>
                        <img
                          alt={asset.originalFilename}
                          src={asset.url}
                          style={img}
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(asset.url)
                          }}
                        />
                      </div>
                      <div className="assetthumbnailactions">
                        <Checkbox
                          className={'assetCheckbox'}
                          checked={showAllAssets}
                          onChange={(e) => {
                            setShowAllAssets(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
              :
              <></>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button size={'small'} onClick={closeSelector}>Cancel</Button>
          <Button size={'small'} onClick={selectFiles} variant={"contained"} color={"primary"}>Select</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
