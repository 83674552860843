import {
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0
} from "@auth0/auth0-react";
import {useNavigate, Outlet} from "react-router-dom";
import * as React from "react";
import secureLocalStorage from "react-secure-storage";
import {useEffect, useState} from "react";

export function PrivateRoute() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [ element, setElement ] = useState<JSX.Element>(<></>);

  const validateAuth = async () => {

    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      secureLocalStorage.removeItem('profile');
      secureLocalStorage.removeItem('organization');
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
          audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
          scope: "openid profile email",
          screen_hint: 'login',
         // organization: `${process.env.REACT_APP_AUTH_ORGANIZATION}`
        }
      });
    }
    else {
      setElement(<Outlet />);
    }
  }

  useEffect(() => {
    setTimeout(async () => { await validateAuth() }, 100);
    return () => {};
  }, [isAuthenticated, isLoading]);

  return element;
}

export const Auth0ProviderWithRedirectCallback = (props: Auth0ProviderOptions) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {props.children}
    </Auth0Provider>
  );
};
