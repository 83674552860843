import Typography from '@mui/material/Typography';

import './unauthorized.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Unauthorized() {

  return (
    <>
      <div className="centercontainer">
        <div className='container'>
          <img src='/logo.png' alt='logo' className='smalllogoimg' />
          <Typography variant="h3" gutterBottom color='#FD9367'>Oops!</Typography>
          <Typography variant="h6" gutterBottom color='#FFFFFF'>You must be connected to access this page.</Typography>
        </div>
      </div>
    </>
  );
}
