import {useCallback, useEffect, useState} from "react";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import {Dashboard} from "../../api";

export function Home() {
  const [state, setState] = useState<Dashboard>();

  const fetchData = useCallback(async () => {
    const organization = secureLocalStorage.getItem('organization') as any;

    httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/dashboard')
      .then(result => {
        setState(result.data);
      });
  }, []);

  useEffect(() => {
    setTimeout( async () => { await fetchData() }, 100);
    return () => {};
  }, []);

  return (
    <>
      {
        state &&
        <div className={'cardContainer'}>
          <div className={'card'}>
            <div className={'cardtitle'}>Customers</div>
            <div>
              <span className={'cardvalue'}>{state?.customers.count}</span>&nbsp;
              <span className={'cardpreviousvalue'}>({state?.customers.previousCount})</span>
            </div>
            <div className={'cardvariation'}>{state?.customers.variation}%</div>
          </div>
          <div className={'card'}>
            <div className={'cardtitle'}>Products</div>
            <div>
              <span className={'cardvalue'}>{state?.products.count}</span>&nbsp;
              <span className={'cardpreviousvalue'}>({state?.products.previousCount})</span>
            </div>
            <div className={'cardvariation'}>{state?.products.variation}%</div>
          </div>
          <div className={'card'}>
            <div className={'cardtitle'}>Assets</div>
            <div>
              <span className={'cardvalue'}>{state?.assets.count}</span>&nbsp;
              <span className={'cardpreviousvalue'}>({state?.assets.previousCount})</span>
            </div>
            <div className={'cardvariation'}>{state?.assets.variation}%</div>
            <div>
              <span className={'cardvalue'}>{(state?.assets.size / 1000000).toFixed(2)}Mo</span>&nbsp;
              <span className={'cardpreviousvalue'}>({(state?.assets.previousSize / 1000000).toFixed(2)}Mo)</span>
            </div>
            <div className={'cardvariation'}>{state?.assets.sizeVariation}%</div>
          </div>
        </div>
      }
    </>
  );
}
