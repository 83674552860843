import './imports.scss';
import AddBoxIcon from "@mui/icons-material/AddBox";
import {useNavigate} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import httpClient from "../../api/AxiosInstance";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {socketService} from "../../websocket/Socket";

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 150,
    //renderCell: (params: GridRenderCellParams) => (<Chip size="small" label={params.value} color="secondary" />)
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    //renderCell: (params: GridRenderCellParams) => (<img className='thumbnail' src={params.row.thumbnailUrl} alt='' />)
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 100,
    //valueGetter: (params) => { return params.row.properties.price }
  },
  {
    field: 'step',
    headerName: 'Step',
    flex: 100,
    //valueGetter: (params) => { return params.row.properties.price }
  },
  {
    field: 'startedAt',
    headerName: 'Started At',
    flex: 150,
    //valueGetter: (params) => { return params.row.properties.material }
  },
  {
    field: 'completedAt',
    headerName: 'Completed At',
    flex: 150,
    //valueGetter: (params) => { return params.row.properties.category }
  }
];

export function ListImports() {
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  useEffect(() => {
    setTimeout(() => { fetchData() }, 100);
    return;
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    const organization = secureLocalStorage.getItem('organization') as any;
    socketService.registerOnServerEvents(organization.environments[0].id, 'default', async (payload: any) => {
      await fetchData();
    });
    return () => {
      socketService.stopConnection();
    };
  }, []);

  const fetchData = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/imports', {
        params: {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize
        }
      })
      .then(result => {
        setPageState(old => ({ ...old, isLoading: false, data: result.data.items, total: result.data.totalCount  }));
      });
  }

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          Imports
        </div>
        <div className="actions">
          <Button startIcon={<AddBoxIcon />} onClick={() => { navigate('/imports/new'); }}>
            New import
          </Button>
          { rowSelectionModel.length > 0 ?
            <Button variant="contained" className='delete' color='error' size='small' startIcon={<DeleteIcon />}>
              Delete {rowSelectionModel.length} item{rowSelectionModel.length > 1 ? 's' : ''}
            </Button>
            : ''
          }
        </div>
        {/*<Paper className="productssearch">*/}
        {/*  <InputBase id="search"*/}
        {/*             sx={{ ml: 1, flex: 1, height: '15px' }}*/}
        {/*             placeholder="Search"*/}
        {/*             inputProps={{ 'aria-label': 'Search' }}*/}
        {/*             onChange={handleSearch}*/}
        {/*  />*/}
        {/*  <IconButton sx={{ p: '10px' }} aria-label="search">*/}
        {/*    <SearchIcon />*/}
        {/*  </IconButton>*/}
        {/*</Paper>*/}
      </div>
      <div className="content">
        <DataGrid
          rows={pageState.data}
          rowCount={pageState.total}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          loading={pageState.isLoading}
          checkboxSelection
          disableColumnMenu
          hideFooterSelectedRowCount={true}
          density="compact"
          onRowClick={(e) => navigate(e.id.toString())}
        />
      </div>
    </div>
  );
}
