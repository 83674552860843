import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './property.scss';

export type UrlPropertyProps = {
    label: string,
    required?: boolean,
    propertyName: string,
    state: any,
    description?: string,
    updateValue: (value: string | undefined) => any
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UrlProperty(props: UrlPropertyProps) {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const pattern = new RegExp('([a-zA-Z]{2,6}):\\/\\/([a-zA-Z0-9.\\-]{2,256}\\.[a-zA-Z]{2,6}\\b):?([0-9]*)?([-a-zA-Z0-9@:%._+~#?&\\/=]*)');

  useEffect(() => {
    if (props.state?.data?.properties) {
      updateValue(props.state.data.properties[props.propertyName])
    }
  }, [props.state]);

  const updateValue = (async (value) => {
    setValue(value);
    if (!pattern.test(value)) {
      setError(true);
      setShowLink(false);
    } else {
      setShowLink(true);
    }
  });

  return (
    <div className='property_container'>
      <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}{props.required ? ' *' : ''}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        {
          !editMode ?
            <span className='property_action'>
              <Typography variant="body1" gutterBottom onClick={() => setEditMode(true)} component="span">
                {value?.length > 0 ? value : 'Not set'}
              </Typography>
              <Link href={value} underline="none" target="_blank" rel="noreferrer" color='secondary' sx={{ mt:'4px', ml: '8px', display: showLink ? 'block' : 'none'}}>
                <OpenInNewIcon sx={{ fontSize: "16px" }} />
              </Link>
            </span>
          :
            <TextField
              sx={{ width: '100%' }}
              type='text'
              size='small'
              required={props.required}
              value={value}
              error={error}
              onBlur={() => { setEditMode(false)}}
              inputRef={input => input && input.focus()}
              onChange={(e) => {
                setError(false);
                if (e.target.value === '' && props.required) setError(true);
                updateValue(e.target.value);
                props.updateValue(e.target.value);
              }}
            />
        }
      </div>
    </div>
  )
}
