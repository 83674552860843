import {useEffect, useState} from 'react';
import './propertygroups.scss';
import {Button, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import SaveIcon from "@mui/icons-material/Save";
import {PropertyGroup, PropertyUnit, ResultPage} from "../../api";

type PropertyGroupState = {
  isLoading: boolean,
  data: PropertyGroup,
  hasModification: boolean
}

export function PropertyGroupDetails() {
  const navigate = useNavigate();
  const { propertyGroupId } = useParams();
  const [options, setOptions] = useState<any>({
    items: Array<any>(),
    selected: Array<any>(),
  });
  const [state, setState] = useState<PropertyGroupState>({
    isLoading: false,
    data: new PropertyGroup(),
    hasModification: false
  });
  const [addState, setAddState] = useState<string[]>();
  const [removeState, setRemoveState] = useState<string[]>();


  const fetchData = async () => {
    const organization = secureLocalStorage.getItem('organization') as any;
    setState(old => ({...old, isLoading: true}));

    httpClient
      .get<ResultPage<PropertyUnit>>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productproperties')
      .then(response => {
        return response.data.items;
      })
      .then(options => {
        if (propertyGroupId !== undefined) {
          httpClient
            .get<PropertyGroup>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertiesgroups/' + propertyGroupId)
            .then(response => {
              const selectedids = response.data.properties?.map(s => s.id);
              const items = options.filter(i => !selectedids || !selectedids.includes(i.id));
              const selected = options.filter(i => selectedids && selectedids.includes(i.id));

              setOptions(old => ({...old, items: items, selected: selected }));
              setState(old => ({...old, isLoading: false, data: response.data}));
            });
        }
        else {
          setOptions(old => ({...old, items: options, selected: undefined }));
        }
      }).finally(() => {
        setState(old => ({...old, isLoading: false}));
      });
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 0);
  }, []);

  const save = async () => {
    setState(old => ({...old, isLoading: true}));

    const organization = secureLocalStorage.getItem('organization') as any;

    const data = state.data;
    data.properties = undefined;
    data.propertyids = options.selected.map(s => s.id);

    if (propertyGroupId === undefined) {
      await httpClient
        .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertiesgroups', JSON.stringify(state.data))
        .then(async () => {
          navigate('/settings/properties/groups');
        });
    } else {
      await httpClient
        .put('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertiesgroups/' + propertyGroupId, JSON.stringify(state.data))
        .then(async () => {
          navigate('/settings/properties/groups');
        });
    }

    setState(old => ({...old, isLoading: false}));
  };

  const removeAll = () => {
    setOptions(old => ({...old, items: [...options.items, ...options.selected], selected: []}));
  }

  const removeSelected = () => {
    const items = options.selected.filter(i => removeState && removeState.includes(i.id));
    const selected = options.selected.filter(i => !removeState || !removeState.includes(i.id));
    setOptions(old => ({...old, items: [...options.items, ...items], selected: selected}));
    setAddState([]);
    setRemoveState([]);
  }

  const addSelected = () => {
    const selected = options.items.filter(i => addState && addState.includes(i.id));
    const items = options.items.filter(i => !addState || !addState.includes(i.id));
    setOptions(old => ({...old, items: items, selected: [...options.selected, ...selected]}));
    setAddState([]);
    setRemoveState([]);
  }

  const addAll = () => {
    setOptions(old => ({...old, items: [], selected: [...options.items, ...options.selected]}));
  }

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          <a onClick={() => {
            navigate('/settings/properties/groups');
          }}><img className={'backBtn'} src='/icons/back.svg' alt='back'/></a>
          {propertyGroupId === undefined ? "New property group" : "Edit property group"}
        </div>
        <div className="actions">
          <Button startIcon={<SaveIcon/>} onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div className="content">
        <div className="formcontainer">
          <TextField
            required
            label={'Name'}
            variant="outlined"
            size="small"
            margin="dense"
            sx={{width: '400px'}}
            InputLabelProps={{shrink: true}}
            value={state.data?.name}
            onChange={(e) => {
              const newData = state.data;
              newData.name = e.target.value;
              setState(old => ({...old, data: newData}));
            }}
          />
          <TextField
            label={'Description'}
            variant="outlined"
            size="small"
            margin="dense"
            multiline
            rows={4}
            sx={{width: '400px'}}
            InputLabelProps={{shrink: true}}
            value={state.data?.description}
            onChange={(e) => {
              const newData = state.data;
              newData.description = e.target.value;
              setState(old => ({...old, data: newData}));
            }}
          />
          <div className={'ordercontainer'}>
            <div style={{display:'flex', flexDirection:'row', flex:1}}>
              <select
                name="items"
                multiple
                size={10}
                style={{flex:1}}
                //value={addState}
                onChange={(event) => {
                  const options = [...event.target.selectedOptions];
                  const values = options.map(option => option.id);
                  setAddState(values);
                }}
              >
                {
                  options.items && options.items.map((item, index) => (
                    <option id={item.id}>{item.name}</option>))
                }
              </select>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <button onClick={removeAll}>&lt;&lt;</button>
                <button onClick={removeSelected}>&lt;</button>
                <button onClick={addSelected}>&gt;</button>
                <button onClick={addAll}>&gt;&gt;</button>
              </div>
              <select
                name="items"
                multiple
                size={10}
                style={{flex:1}}
                //value={removeState}
                onChange={(event) => {
                  const options = [...event.target.selectedOptions];
                  const values = options.map(option => option.id);
                  setRemoveState(values);
                }}
              >
                {
                  options.selected && options.selected.map((item, index) => (
                    <option id={item.id} key={index}>{item.name}</option>))
                }
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
