import {useNavigate, useParams} from "react-router-dom";
import {forwardRef, useEffect, useState} from "react";
import {Button, Divider, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import httpClient from "../../api/AxiosInstance";
import Select from '@mui/material/Select';
import {Property, PropertyUnit} from "../../api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import SaveIcon from "@mui/icons-material/Save";

type PropertyState = {
  isLoading: boolean,
  data: Property,
  units: PropertyUnit[] | undefined
  hasModification: boolean,
}

type PropertyOptions = {
  isVisible: boolean,
  isSelected: boolean,
  value: boolean | number
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowedDecimalSeparators={[',']}
        valueIsNumericString
        decimalScale={2}
      />
    );
  },
);

export function AddProperties() {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [state, setState] = useState<PropertyState>({
    isLoading: false,
    data: new Property(),
    units: undefined,
    hasModification: false
  });

  const [requiredValue, setRequiredValue] = useState<PropertyOptions>({
    isVisible: false,
    isSelected: false,
    value: false
  });
  const [minValue, setMinValue] = useState<PropertyOptions>({
    isVisible: false,
    isSelected: false,
    value: 0
  });
  const [maxValue, setMaxValue] = useState<PropertyOptions>({
    isVisible: false,
    isSelected: false,
    value: 0
  });
  const [minLengthValue, setMinLengthValue] = useState<PropertyOptions>({
    isVisible: false,
    isSelected: false,
    value: 0
  });
  const [maxLengthValue, setMaxLengthValue] = useState<PropertyOptions>({
    isVisible: false,
    isSelected: false,
    value: 0
  });

  const fetchData = async () => {
    setState(old => ({...old, isLoading: true}));

    const organization = secureLocalStorage.getItem('organization') as any;

    httpClient
      .get('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productpropertyunits')
      .then(response => {
        setState(old => ({...old, units: response?.data?.items}));
      })
      .then(() => {
        if (propertyId !== undefined) {
          httpClient
            .get<Property>('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productproperties/' + propertyId)
            .then(response => {
              selectType(response.data?.type);

              setRequiredValue(old => ({...old, value: response.data?.parameters && 'required' in response.data.parameters && response.data.parameters['required'] === 'true'}));
              setMinValue(old => ({...old, isSelected: response.data?.parameters && 'min' in response.data.parameters, value: response.data?.parameters && 'min' in response.data.parameters ? +response.data.parameters['min'] : 0}));
              setMaxValue(old => ({...old, isSelected: response.data?.parameters && 'max' in response.data.parameters, value: response.data?.parameters && 'max' in response.data.parameters ? +response.data.parameters['max'] : 0}));
              setMinLengthValue(old => ({...old, isSelected: response.data?.parameters && 'minlength' in response.data.parameters, value: response.data?.parameters && 'minlength' in response.data.parameters ? +response.data.parameters['minlength'] : 0}));
              setMaxLengthValue(old => ({...old, isSelected: response.data?.parameters && 'maxlength' in response.data.parameters, value: response.data?.parameters && 'maxlength' in response.data.parameters ? +response.data.parameters['maxlength'] : 0}));

              setState(old => ({...old, data: response.data }));
            });
        }
      })
      .finally(() => {
        setState(old => ({...old, isLoading: false}));
      });
  }

  useEffect(() => {
    setTimeout(async () => { await fetchData() }, 0);
  }, []);

  const selectType = (type: string | undefined) => {

    const requiredSelected = type === 'String' || type === 'URL' || type === 'Paragraph' ||
      type === 'Html' || type === 'Integer' || type === 'Number' ||
      type === 'Boolean' || type === 'Multiselect' || type === 'Dropdown' ||
      type === 'SingleMedia' || type === 'MediaGallery' || type === 'Category' || type === 'DateTime';
    const minSelected = type === 'Integer' || type === 'Number';
    const maxSelected = type === 'Integer' || type === 'Number';
    const minLengthSelected = type === 'String' || type === 'Paragraph' || type === 'Html';
    const maxLengthSelected = type === 'String' || type === 'Paragraph' || type === 'Html';

    setRequiredValue(old => ({...old, isVisible: requiredSelected}));
    setMinValue(old => ({...old, isVisible: minSelected}));
    setMaxValue(old => ({...old, isVisible: maxSelected}));
    setMinLengthValue(old => ({...old, isVisible: minLengthSelected}));
    setMaxLengthValue(old => ({...old, isVisible: maxLengthSelected}));

    const data = state.data;
    data.type = type;
    setState(old => ({...old, data: data }));
  }

  const save = async () => {
    setState(old => ({...old, isLoading: true}));

    const organization = secureLocalStorage.getItem('organization') as any;
    const data = state.data!;
    if (data.unitId && data.unitId === 'none') {
      data.unitId = undefined;
    }

    if (data.parameters === undefined) {
      data.parameters = {}
    }

    if (requiredValue.isVisible && requiredValue.value) {
      data.parameters['required'] = 'true';
    } else {
      delete data.parameters['required'];
    }
    if (minValue.isVisible && minValue.isSelected) {
      data.parameters['min'] = '' + minValue.value;
    } else {
      delete data.parameters['min'];
    }
    if (maxValue.isVisible && maxValue.isSelected) {
      data.parameters['max'] = '' + maxValue.value;
    } else {
      delete data.parameters['max'];
    }
    if (minLengthValue.isVisible && minLengthValue.isSelected) {
      data.parameters['minlength'] = '' + minLengthValue.value;
    } else {
      delete data.parameters['minlength'];
    }
    if (maxLengthValue.isVisible && maxLengthValue.isSelected) {
      data.parameters['maxlength'] = '' + maxLengthValue.value;
    } else {
      delete data.parameters['maxlength'];
    }

    if (propertyId === undefined) {
      await httpClient
        .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productproperties', JSON.stringify(data))
        .then(async () => {
          navigate('/settings/properties');
        })
        .catch(() => {
          setState(old => ({...old, isLoading: false}));
        });
    } else {
      await httpClient
        .put('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/productproperties/' + propertyId, JSON.stringify(data))
        .then(async () => {
          navigate('/settings/properties');
        })
        .catch(() => {
          setState(old => ({...old, isLoading: false}));
        });
    }
  };

  return (
    <div className='contentContainer'>
      <div className='header'>
        <div className='title'>
          <a onClick={() => {
            state.data?.type === undefined || propertyId !== undefined ? navigate('/settings/properties') : selectType(undefined)
          }}><img alt={'back'} className={'backBtn'} src='/icons/back.svg'/></a>
          {state.data?.type ? propertyId ? 'Edit ' + state.data?.type + ' property' : 'New ' + state.data?.type + ' property' : 'Select new property type'}
        </div>
        <div className="actions">
          <Button startIcon={<SaveIcon/>} onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div className="content">
        {state.data?.type === undefined && propertyId === undefined ?
          <div className="propertytypes">
            <a onClick={() => {
              selectType('String')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>String</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('URL')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>URL</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Paragraph')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Paragraph</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Html')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>HTML</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Integer')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Integer</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Number')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Number</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Boolean')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Boolean</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Multiselect')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Multiselect</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Dropdown')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Dropdown</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('SingleMedia')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>SingleMedia</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('MediaGallery')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>MediaGallery</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('Category')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/home.svg' alt='home'/>
                <p>Category</p>
              </div>
            </a>
            <a onClick={() => {
              selectType('DateTime')
            }}>
              <div className={'propertytype'}>
                <img src='/icons/datetime.svg' alt='home'/>
                <p>DateTime</p>
              </div>
            </a>
          </div>
          :
          <div className="formcontainer">
            <TextField
              required
              label={'Name'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{shrink: true}}
              value={state.data?.name}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.name = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}/>
            <TextField
              required
              label={'Code'}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '400px'}}
              InputLabelProps={{shrink: true}}
              value={state.data?.code}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.code = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}/>
            <FormControl>
              <InputLabel id="selectlabel" size={'small'}>Unit</InputLabel>
              <Select
                labelId="selectlabel"
                label={'Unit'}
                size="small"
                margin="dense"
                variant="outlined"
                sx={{width: '400px', textAlign: 'left'}}
                value={state.data?.unitId ?? 'none'}
                defaultValue={'none'}
                onChange={(e) => {
                  if (state.data) {
                    const newData = state.data;
                    newData.unitId = e.target.value;
                    setState(old => ({...old, data: newData}));
                  }
                }}
              >
                <MenuItem key={'none'} value={'none'}>
                  None
                </MenuItem>
                <Divider/>
                {state.units?.map((c) => (
                  <MenuItem key={c.name} value={c.id}>
                    {c.name} ({c.unit})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label={'Description'}
              variant="outlined"
              size="small"
              margin="dense"
              multiline
              rows={4}
              sx={{width: '400px'}}
              InputLabelProps={{shrink: true}}
              defaultValue=''
              value={state.data?.description}
              onChange={(e) => {
                if (state.data) {
                  const newData = state.data;
                  newData.description = e.target.value;
                  setState(old => ({...old, data: newData}));
                }
              }}
            />
            <FormControlLabel
                label="Used by AI"
                sx={{width: '400px'}}
                control={<Checkbox
                  checked={state.data.includedInAi as boolean}
                  onChange={(e) => {
                    if (state.data) {
                      const newData = state.data;
                      newData.includedInAi = e.target.checked;
                      setState(old => ({...old, data: newData}));
                    }
                  }}/>}
              />
            {requiredValue.isVisible ?
              <FormControlLabel
                label="Is required"
                sx={{width: '400px'}}
                control={<Checkbox
                  checked={requiredValue.value as boolean}
                  onChange={(e) => {
                    setRequiredValue(old => ({...old, value: e.target.checked}));
                  }}/>}
              /> : <></>
            }
            {minValue.isVisible ?
              <>
                <FormControlLabel
                  label="Specify minimum value"
                  sx={{width: '400px'}}
                  control={<Checkbox
                    checked={minValue.isSelected as boolean}
                    onChange={(e) => {
                      setMinValue(old => ({...old, isSelected: e.target.checked}));
                    }}/>}
                />
                { minValue.isSelected ?
                  <TextField
                    value={minValue.value}
                    sx={{width: '100px'}}
                    onChange={(e) => {
                      setMinValue(old => ({...old, value: +e.target.value}));
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    variant="standard"
                  /> : <></>
                }
              </>: <></>
            }
            {maxValue.isVisible ?
              <>
                <FormControlLabel
                  label="Specify maximum value"
                  sx={{width: '400px'}}
                  control={<Checkbox
                    checked={maxValue.isSelected as boolean}
                    onChange={(e) => {
                      setMaxValue(old => ({...old, isSelected: e.target.checked}));
                    }}/>}
                />
                {maxValue.isSelected ?
                  <TextField
                    value={maxValue.value}
                    sx={{width: '100px'}}
                    onChange={(e) => {
                      setMaxValue(old => ({...old, value: +e.target.value}));
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    variant="standard"
                  /> : <></>
                }
              </>: <></>
            }
            {minLengthValue.isVisible ?
              <>
                <FormControlLabel
                  label="Specify minimum length"
                  sx={{width: '400px'}}
                  control={<Checkbox
                    checked={minLengthValue.isSelected as boolean}
                    onChange={(e) => {
                      setMinLengthValue(old => ({...old, isSelected: e.target.checked}));
                    }}/>}
                />
                {minLengthValue.isSelected ?
                  <TextField
                    value={minLengthValue.value}
                    sx={{width: '100px'}}
                    onChange={(e) => {
                      setMinLengthValue(old => ({...old, value: +e.target.value}));
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    variant="standard"
                  /> : <></>
                }
              </>: <></>
            }
            {maxLengthValue.isVisible ?
              <>
                <FormControlLabel
                  label="Specify maximum length"
                  sx={{width: '400px'}}
                  control={<Checkbox
                    checked={maxLengthValue.isSelected as boolean}
                    onChange={(e) => {
                      setMaxLengthValue(old => ({...old, isSelected: e.target.checked}));
                    }}/>}
                />
                {maxLengthValue.isSelected ?
                  <TextField
                    value={maxLengthValue.value}
                    sx={{width: '100px'}}
                    onChange={(e) => {
                      setMaxLengthValue(old => ({...old, value: +e.target.value}));
                    }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    variant="standard"
                  /> : <></>
                }
              </>: <></>
            }
          </div>}
      </div>
    </div>
  );
}
