import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import './property.scss';
import ReactQuill from "react-quill";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import secureLocalStorage from 'react-secure-storage';
import httpClient from '../../api/AxiosInstance';
import MarkdownEditor from '@uiw/react-markdown-editor';

export type HtmlPropertyProps = {
  minlength?: number,
  maxlength?: number,
  label: string,
  required?: boolean,
  propertyName: string,
  state: any,
  description?: string,
  updateValue: (value: string | undefined) => any
};

export function HtmlProperty(props: HtmlPropertyProps) {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (props.state?.data?.properties) {
     setValue(props.state.data.properties[props.propertyName])
    }
  }, [props.state]);

  const generateDescription = async () => {
    const organization = secureLocalStorage.getItem('organization') as any;
    await httpClient
      .post('/api/v1/organizations/' + organization.id + '/environments/' + organization.environments[0].id + '/products/' + props.state?.data?.id + '/ai/description')
      .then(result => {
        setValue(result.data);
        props.updateValue(value);
      });
  }

  return (
    <div className='property_container'>
      <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}{props.required ? ' *' : ''}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div style={{height:'250px', width:'1000px'}}>
          {
            // <ReactQuill
            //   theme="snow"
            //   value={value}
            //   style={{height:'200px'}}
            //   onChange={(e) => {
            //     setError(false);
            //     if (e === '' && props.required) setError(true);
            //     if (props.maxlength && e.length > props.maxlength) setError(true);
            //     if (props.minlength && e.length < props.minlength) setError(true);
            //     setValue(e);
            //     props.updateValue(e); }} />

            <MarkdownEditor
              value={value}
              height="200px"
              enableScroll={true}
              onChange={(value, viewUpdate) => {
                  if (props.maxlength && value.length > props.maxlength) setError(true);
                  if (props.minlength && value.length < props.minlength) setError(true);
                  setValue(value);
                  props.updateValue(value); }} />
          }
          </div>
          <div>
            <Tooltip disableFocusListener disableTouchListener title='Let AI generate the description!' placement='left'>
              <IconButton aria-label="help" size='small' onClick={generateDescription}>
                <AutoFixHighIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
