import { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import './property.scss';

export type NumberPropertyProps = {
    min?: number,
    max?: number,
    label: string,
    required?: boolean
    propertyName: string,
    state: any,
    description?: string,
    updateValue: (value: string | undefined) => any
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NumberProperty(props: NumberPropertyProps) {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const min = props.min;
  const max = props.max;
  const oldValueRef = useRef<string>('');
  const pattern = new RegExp('^-?[0-9]*\\.?[0-9]*$');

  useEffect(() => {
    if (props.state?.data?.properties) {
      setValue(props.state.data.properties[props.propertyName]);
    }
  }, [props.state]);

  return (
    <div className='property_container'>
     <div className='property_name'>
        <Typography component={'span'} variant="body2">
          {props.label}{props.required ? ' *' : ''}
        </Typography>
        {
          props.description ?
            <Tooltip disableFocusListener disableTouchListener title={props.description} placement='right'>
              <IconButton aria-label="help" size='small'>
                <HelpIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          : <></>
        }
      </div>
      <div className='property_value'>
        {
          !editMode ?
            <Typography variant="body1" gutterBottom onClick={() => setEditMode(true)} component="span">
              {value && value?.length > 0 ? value : 'Not set'}
            </Typography>
          :
            <TextField
              sx={{ width: '100%', mb: '8px' }}
              type='text'
              size='small'
              required={props.required}
              value={value}
              error={error}
              onBlur={() => { setEditMode(false)}}
              inputRef={input => input && input.focus()}
              onChange={(e) => {
                if (pattern.test(e.target.value)) {
                  if (e.target.value === '' || e.target.value === '-') {
                      setValue(e.target.value);
                      setError(props.required === undefined ? false : props.required);
                  } else {
                    const value = parseInt(e.target.value, 10);

                    setError(false);
                    if (max !== undefined && value > max) setError(true);
                    if (min !== undefined && value < min) setError(true);

                    oldValueRef.current = value.toString();
                    setValue(value.toString());
                    props.updateValue(value.toString());
                  }
                } else {
                  setValue(oldValueRef.current);
                  props.updateValue(oldValueRef.current);
                }
              }}
            />
        }
      </div>
    </div>
  )
}
